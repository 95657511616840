import _ from 'lodash'
import CommonConstant from '~@app/module/core/common/CommonConstant'
import Family from '~@app/module/core/model/Family'
import FamilyMember from '~@app/module/core/model/FamilyMember'
import FamilyMemberConstant from '~@app/module/family/constant/FamilyMemberConstant'
import {AnyArrayOf, AnyNumber} from '~@core/type/Common'
import {trans} from '~@core/util/StringUtil'

function partialDateTime(day, month, year, delim = '/') {
  return _.chain([day, month, year]).filter().join(delim).value();
}

function partialLongDateTime(day, month, year, delim = '/') {
  const dTxt = day ? `${trans('date')} ${day}` : day;
  const mTxt = month === null ? month : trans(`datetime.month.${month}`, `${month}`);
  const yTxt = day ? `${trans('year')} ${year}` : year;
  return _.chain([dTxt, mTxt, yTxt]).filter().join(delim).value();
}

function toAge(year, now?: AnyNumber) {
  const n = (now === undefined || now === null) ? new Date().getFullYear() : now;
  return year ? Math.abs(year - n) : null;
}

function yobyod(yob, yod) {
  return _.chain([yob, yod]).filter().join(' - ').value();
}

function checkRel(member: FamilyMember, relMember: FamilyMember | undefined) {
  if (!member || !relMember) {
    return null
  }
  if (member.id === relMember?.id) {
    return 'self';
  }
  if (member.id === relMember.fatherId) {
    return 'father';
  } if (member.id === relMember.motherId) {
    return 'mother';
  } if ((member.fatherId && (member.fatherId === relMember.id || member.fatherId === relMember.spouseId))
    || (member.motherId && (member.motherId === relMember.id || member.motherId === relMember.spouseId))) {
    if (member.gender === CommonConstant.GENDER.MALE) {
      return 'son';
    } if (member.gender === CommonConstant.GENDER.FEMALE) {
      return 'daughter';
    } 
    return 'child';
    
  } if (member.spouseId === relMember.id || member.id === relMember.spouseId) {
    if (member.gender === CommonConstant.GENDER.MALE) {
      if (member.maritalStatus === FamilyMemberConstant.MARITAL_STATUS.DIVORCED) {
        return 'exHusband';
      }
      return 'husband';
    } if (member.gender === CommonConstant.GENDER.FEMALE) {
      if (member.maritalStatus === FamilyMemberConstant.MARITAL_STATUS.DIVORCED) {
        return 'exWife';
      }
      return 'wife';
    } 
    return 'spouse';
    
  }
}

function sortSpouses(member: FamilyMember, spouses: AnyArrayOf<FamilyMember>) {
  const inLaws = _.filter(spouses, e => {
    const rel = checkRel(e, member);
    return rel === 'wife' || rel === 'husband';
  })
  const others = _.without(spouses, ...inLaws)
  return {
    inLaws,
    others
  }
}

function getPropertyValue<T = React.ReactElement>(family: Family | undefined, property: string, defValue?: T) {
  return _.get(family?.properties, property, defValue) as T | undefined;
}

function getPropOfJsonPropertyValue<T = React.ReactElement>(family: Family | undefined, property: string, subProp: string, defValue?: T) {
  const value = getPropertyValue(family, property) as string;
  if (value) {
    return _.get(JSON.parse(value), subProp) ?? defValue
  }
  return defValue;
}

function isDead(member: FamilyMember) {
  return !!member.dodYear || !!member.dodMonth;
}

const FamilyUtil = {
  partialDateTime,
  partialLongDateTime,
  toAge,
  yobyod,
  checkRel,
  sortSpouses,
  getPropertyValue,
  getPropOfJsonPropertyValue,
  isDead
}
export default FamilyUtil