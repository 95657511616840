import Blog from "~@app/module/core/model/Blog"
import _ from "lodash"

export default {
  getBlogMainImage(blog: Blog) {
    if (blog?.mainImage) {
      return blog.mainImage
    }
    const galleryMedia = _.first(blog?.gallery?.mediaData);
    return galleryMedia?.media;
  }
}