import moment from 'moment'
import {AnyString} from '~@core/type/Common'

export default {
  date: {
    format(date, fmt: AnyString) {
      const d = moment(date);
      if (!d) {
        return ''
      }
      return fmt ? d.format(fmt) : d.toISOString();
    },
    dateFmt(date) {
      return this.format(date, 'DD/MM/YYYY')
    },
    toDate(from, to) {
      return `${this.dateFmt(from)} - ${this.dateFmt(to)}`
    },
    dateTimeFmt(date) {
      return this.format(date, 'YYYY-MM-DD hh:mm');
    },
    now() {
      return moment();
    },
    add(amount, period, since?) {
      return moment(since).add(amount, period);
    },
    ageByYear(date, since) {
      const from = since ? moment(since) : this.now();
      const d = moment(date);
      return Math.abs(d.year() - from.year());
    },
    diffMonth(from, to) {
      const a = moment(from);
      const b = moment(to).endOf('day');
      return Math.abs(a.diff(b, 'months'));
    },
    diffYear(from, to) {
      const a = moment(from);
      const b = moment(to).endOf('day');
      return Math.abs(a.diff(b, 'years'));
    },
    diffDay(from, to) {
      const a = moment(from);
      const b = moment(to);
      return Math.abs(a.diff(b, 'days'));
    }
  },
  number: {
    percentage(value, radix, suffix = '%') {
      const v = value || 0;
      const r = radix || 1;
      if (Math.abs(v) > r) {
        return 'invalid';
      }
      return `${(v * 100) / r}${suffix}`;
    }
  },
}
