import API from '~@app/const/API';

export default {
  API: {
    QUERY: {
      LIST: `${API.BASE.API_QUERY_PATH}blog/search`
    },
    COMMAND: {
      CHANGE_PASSWORD: `${API.BASE.API_COMMAND_PATH}api/client/v1/account/change-password`
    },
    QUERY_WITH: {
      LISTING: ['*', 'gallery.mediaData', 'mainImage', ['author', {with: ['*']}], 'categories'],
      DETAIL: ['*', 'gallery.mediaData', 'mainImage', ['author', {with: ['*']}], 'next', 'prev', 'categories']
    }
  },
  CONTENT_TYPE: {
    ARTICLE: 'article',
    GALLERY: 'gallery'
  }
};