import {Box, BoxProps} from "@mui/material"
import React, {FC, useMemo} from 'react';

interface ScaleBoxProps extends BoxProps {
  w?: number,
  h?: number,
}

const ScaleBox: FC<ScaleBoxProps> = ({w, h, children, ...props}) => {
  const ratio = useMemo(() => {
    const w1 = w || 1;
    const h1 = h || 1;
    return `${(h1 * 100 / w1).toFixed(2)}% !important`
  }, [w, h])

  return <Box {...props} className={`rich-box ${props.className ?? ''}`} sx={{
    ...props?.sx,
    height: 0,
    position: 'relative',
    paddingBottom: ratio
  }}
  >
    {children && <Box className="rich-box-content" sx={{
      position: 'absolute',
      left: 0,
      width: '100%',
      height: '100%'
    }}>
      {children}
    </Box>
    }
  </Box>
};

export default ScaleBox;