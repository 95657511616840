import {Box, Card} from '@mui/material'
import _ from 'lodash'
import React from 'react';
import useApp from '~@app/component/app/UseApp'
import '~@app/module/blog/component/BlogGrid.scss'
import BlogConstant from '~@app/module/blog/constant/BlogConstant'
import BlogUtil from '~@app/module/blog/util/BlogUtil'
import ScaleBox from '~@app/module/common/component/ScaleBox'
import Blog from '~@app/module/core/model/Blog'
import Image from '~@core/component/common/Image'
import DateTimeUtil from '~@core/util/DateTimeUtil'
import {trans} from '~@core/util/StringUtil'

export interface BlogGridProps {
  blogs?: Blog[]
  className?: string
  variant?: 'style1' | 'style2'
}

const BlogGrid = ({blogs, className, variant = 'style1'}: BlogGridProps) => {

  const app = useApp();

  function getBlogUrl(blog: Blog) {
    if (blog.contentType === BlogConstant.CONTENT_TYPE.GALLERY) {
      return app.pageUrl(`gallery/${blog.slug}`);
    } if (blog.contentType === BlogConstant.CONTENT_TYPE.ARTICLE) {
      return app.pageUrl(`blog/${blog.slug}`);
    }
  }

  const renderItem = (blog: Blog, idx) => {
    const media = BlogUtil.getBlogMainImage(blog);
    const imgUrl = media?.links?.medium;

    const enableSpan = (blogs?.length ?? 0) > 3
    const mergeSpan = idx % 10 === 0 || (idx - 7) % 10 === 0
    return <Box className="blog-item" key={idx} gridRow={enableSpan && mergeSpan ? 'span 2' : ''}>
      <Card className="blog-item-card" sx={{
        backgroundImage: `url("${imgUrl}")`
      }}>
        <ScaleBox w={4} h={3}/>
        <div className="blog-content">
          <div className="created">{trans('day')} {DateTimeUtil.dateFmt(blog.updatedAt)}</div>
          <div className="title">
            <a href={getBlogUrl(blog)}>{blog.title}</a>
          </div>
          {
            blog?.author?.displayName && <div className="author">
              <Image
                className="author-img"
                url={blog.author.mainImage?.links?.medium}
                title={blog.author.displayName}
              />
              <div className="author-name">{blog?.author?.displayName}</div>
            </div>
          }
        </div>
      </Card>
    </Box>
  }

  return <div className={`blog-grid ${className ?? ''} ${variant}`}>
    <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={4}>
      {_.map(blogs, renderItem)}
    </Box>
  </div>
};

export default BlogGrid;