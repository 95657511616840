import API from '~@app/const/API';
import BaseTag from '~@app/module/core/model/BaseTag'
import Blog from '~@app/module/core/model/Blog';
import CRUDService from '~@core/service/CRUDService';
import {Collection, SuccessResponse} from '~@core/type/ApiType'
import {AnyObject} from '~@core/type/Common'

class BlogService extends CRUDService<Blog> {

  async tags(payload?) {
    const ret = await this.http.callPost(`${this.queryPath}/tags`, payload);
    return ret as SuccessResponse<Collection<BaseTag>>;
  }

  async byTag(tag: string, payload?) {
    const ret = await this.http.callPost(`${this.queryPath}/by-tag`, {
      tag,
      ...payload
    });
    return ret as SuccessResponse<Collection<Blog>>;
  }

  async getBySlug(slug: string, payload: AnyObject = null) {
    const pl = this.buildReadPayload({
      ...payload,
      filters: [['slug', '=', slug]]
    });
    const ret = await this.http.callPost(`${this.queryPath}/find-by`, pl);
    return ret as SuccessResponse<Blog>;
  }

  async react(id: string, type: 'like' | 'unlink' | 'view') {
    const pl = this.buildWith({
      id,
      type
    });
    const ret = await this.http.callPost(`${this.queryPath}/react`, pl);
    return ret as SuccessResponse<{
      success: boolean
    }>;
  }
}

export default new BlogService(`${API.BASE.API_COMMAND_PATH}blog`, `${API.BASE.API_QUERY_PATH}blog`);