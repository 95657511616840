import moment from 'moment';
import DefaultFunction from "~@app/module/common/type/dynamicform/DefaultFunction";

export default {
  ...DefaultFunction.date,
  compare(date1, date2) {
    const mm1 = moment(date1);
    const mm2 = moment(date2);
    if (mm1.isAfter(mm2)) {
      return 1;
    }
    if (mm1.isBefore(mm2)) {
      return -1;
    }
    return 0;
  },
  compareWithNow(date1) {
    return this.compare(date1, moment());
  },
  compareByPeriod(date1, amount, period) {
    const mm1 = moment(date1);
    const mm2 = moment().add(amount, period);
    if (mm1.isAfter(mm2)) {
      return 1;
    }
    if (mm1.isBefore(mm2)) {
      return -1;
    }
    return 0;
  }
};